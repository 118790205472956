/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const FavouriteFilledIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="m9.34 3.357-.003.003A4.877 4.877 0 0 0 5.96 2C3.22 2 1 4.278 1 7.087a5.14 5.14 0 0 0 1.326 3.462l-.003.002 6.979 7.156a.97.97 0 0 0 1.396 0l6.979-7.156-.003-.002A5.14 5.14 0 0 0 19 7.087C19 4.277 16.779 2 14.04 2c-1.305 0-2.491.516-3.377 1.36l-.002-.003-.661.678-.66-.678Z"
        />
    </svg>
);
const ForwardRef = forwardRef(FavouriteFilledIcon);
export default ForwardRef;
